<script src="../../../../../../../yilanyun/newservice_manager3.0/evui/src/config/setting.js"></script>
<template>
  <div class="ele-body">
    <el-card shadow="never">
      <!-- 搜索表单 -->
      <el-form :model="table.where" class="ele-form-search ele-form-mysearch d-flex f-end"
        style="float: left;display: flex;justify-content: flex-start;flex-wrap: wrap;"
        @keyup.enter.native="$refs.table.reload()" @submit.native.prevent>
        <el-form-item label="提现人类型：" label-width="100px" class="w-200">
          <el-select v-model="table.where.role_type" placeholder="请选择" clearable class="ele-fluid">
            <el-option label="全部" value="" />
            <el-option label="司机" :value="1" />
            <el-option label="跑腿" :value="2" />
            <el-option label="超市" :value="3" />
            <el-option label="叫餐" :value="4" />
          </el-select>
        </el-form-item>
        <el-form-item label="提现方式：" label-width="100px" class="w-200">
          <el-select v-model="table.where.pay_type" placeholder="请选择" clearable class="ele-fluid">
            <!-- <el-option  label="全部" :value="10"/> -->
            <el-option label="微信" :value="1" />
            <el-option label="支付宝" :value="2" />
          </el-select>
        </el-form-item>
        <el-form-item label="省:" label-width="35px" class="w-150">
          <el-select v-model="table.where.pid" @change="handleChangeProv(table.where.pid)" placeholder='请选择省' clearable>
            <el-option v-for="option in provArr" :value="option.pid" :key="option.pid" :label="option.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="市:" label-width="35px" class="w-170">
          <el-select v-model="table.where.cid" @change="handleChangeCity(table.where.cid)" placeholder='请选择市' clearable>
            <el-option v-for="option in cityArr" :value="option.cid" :key="option.cid" :label="option.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="县/区:" label-width="62px" class="w-200">
          <el-select v-model="table.where.aid" @change="$forceUpdate()" placeholder='请选择县/区' clearable>
            <el-option v-for="option in districtArr " :value="option.aid" :key="option.aid"
              :label="option.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="申请开始时间：" label-width="110px">
          <el-date-picker :picker-options="pickerOptionsStart" style="margin-right: 10px" popper-class="no-atTheMoment"
            @change="birthdayChange" v-model="tableCreate_time" value-format="yyyy-MM-dd HH:mm:ss"
            format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="选择日期"></el-date-picker>
        </el-form-item>
        <el-form-item label="申请结束时间：" label-width="110px" style=" margin-left: 4px;">
          <el-date-picker :picker-options="pickerOptionsEnd" style="margin-right: 10px;" popper-class="no-atTheMoment"
            @change="birthdayChange1" v-model="tableend_time" value-format="yyyy-MM-dd HH:mm:ss"
            format="yyyy-MM-dd HH:mm:ss" type="datetime" placeholder="选择日期"></el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="$refs.table.reload()" icon="el-icon-search"
            class="ele-btn-icon ml-20">搜索</el-button>
          <el-button @click="(table.where = {}) && $refs.table.reload()">重置</el-button>
          <download style="margin-left: 0" class="ele-btn-icon ml-20" label="导出" url="finance/withecordExportExcel"
            :params="exportOrderParams()" />
        </el-form-item>
      </el-form>
      <!-- 数据表格 -->
      <ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)" highlight-current-row
        :stripe=true>
        <template slot-scope="{index}">
          <el-table-column type="selection" width="45" align="center" fixed="left" />
          <el-table-column type="index" :index="index" label="编号" width="60" align="center" fixed="left"
            show-overflow-tooltip />
          <el-table-column prop="driver_address" label="所在地区" show-overflow-tooltip min-width="120" />
          <el-table-column prop="true_name" label="提现人姓名" show-overflow-tooltip min-width="120" />
          <el-table-column prop="driver_phone" label="账号" show-overflow-tooltip min-width="120" />
          <el-table-column prop="type_name" label="提现人类型" show-overflow-tooltip min-width="120" />
          <el-table-column prop="money" label="提现金额(元)" show-overflow-tooltip min-width="120" />
          <el-table-column prop="pay_type_name" label="提现方式" show-overflow-tooltip min-width="120" />
          <el-table-column label="申请时间" show-overflow-tooltip min-width="160">
            <template slot-scope="{row}">{{ row.create_time * 1000 | toDateString }}</template>
          </el-table-column>
          <el-table-column label="操作" width="200px" align="center" :resizable="false" fixed="right">
            <template slot-scope="{row}">
              <el-link slot="reference" icon="el-icon-success" @click="with_success(row)" type="primary"
                :underline="false" v-if="permission.includes('sys:with_ecord:with_success')">通过</el-link>
              <el-link slot="reference" icon="el-icon-error" @click="with_error(row)" type="danger" :underline="false"
                v-if="permission.includes('sys:with_ecord:with_error')">驳回</el-link>
              <el-link slot="reference" icon="el-icon-view" @click="view_records(row)" type="primary" :underline="false"
                v-if="permission.includes('sys:with_ecord:view_records')">查看提现账单</el-link>
            </template>
          </el-table-column>
        </template>
      </ele-data-table>
    </el-card>

    <!--提现订单明细-->
    <el-dialog v-dialogDrag title="提现订单明细" :visible.sync="dialogTableVisibleRecords" width="1000px"
      :destroy-on-close="true" :lock-scroll="false">
      <el-card shadow="never">
        <el-form label-width="77px" class="ele-form-search mb-10 d-flex" style="float:right;width: 100%; display: block">
          <div style="color: #1c6ca1; float: left; line-height: 36px">提现订单合计金额：{{ withdraw_price }}元</div>
          <download style="margin-left: 0; float: right" class="ele-btn-icon ml-20" label="导出明细"
            url="Finance/exportExcelRecords"
            :params="{ uid: row_data.uid, earning_id: row_data.earning_id, id: row_data.id, type: 1 }" />
        </el-form>
        <el-table :data="recordsList" element-loading-text="列表正在加载中" border fit highlight-current-row
          style="width: 100%;">
          <el-table-column type="编号" prop="index" label="编号" width="60" align="center" fixed="left"
            show-overflow-tooltip />
          <el-table-column prop="name" label="司机姓名" show-overflow-tooltip min-width="120" />
          <el-table-column prop="phone" label="手机号" show-overflow-tooltip min-width="120" />
          <el-table-column prop="order_id" label="订单号" show-overflow-tooltip min-width="120" />
          <el-table-column prop="money" label="订单金额(元)" show-overflow-tooltip min-width="120" />
          <el-table-column prop="get_money" label="实际到账金额(元)" show-overflow-tooltip min-width="120" />
          <el-table-column prop="price" label="手续费(元)" show-overflow-tooltip min-width="120" />
          <el-table-column label="操作" width="200px" align="center" :resizable="false" fixed="right">
            <template slot-scope="{row}">
              <el-link slot="reference" icon="el-icon-view" @click="getChat(row.orderid)" type="primary"
                :underline="false">订单聊天记录</el-link>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination v-if="recordsShow" @current-change="handleCurrentChange" :current-page.sync="pageNumber"
          layout="total, prev, pager, next, jumper" :page-sizes="[5, 10, 15, 20]" :page-size="10" :total="recordsTotal">
        </el-pagination>
      </el-card>
    </el-dialog>

    <!--    聊天记录    -->
    <el-dialog v-dialogDrag title="聊天记录" :visible.sync="dialogTableVisibleChatRecord" class="records" @close='closeDialog'
      :destroy-on-close="true" :lock-scroll="false" width="600px">




      <div class="charRecord-box">
        <div class="driverBox" style="padding:20px;width: 500px;" v-if="charList != ''">
          <div class="" style="width:80px;height:80px;">
            <img style="width:60px;height:60px;border-radius:50%" :src="driverInfo.headimg" />
          </div>
          <div class="">
            <div class="" style="font-size:17px;margin:5px 0">{{ driverInfo.name }}</div>
            <div class="" style="font-size:13px">{{ driverInfo.car_number }}</div>
          </div>
        </div>
        <div>


          <div v-if="charList == ''">
            <el-alert title=" 收款码收入" type="info" center :closable="false" description="可在“财务流水列表”中凭订单号进行核对" show-icon>
            </el-alert>
          </div>

          <div class="charRecord-inner" v-for="( option, index ) in  charList " :key="option.id">
            <!-- 时间 -->
            <!-- <div class="w-100" v-if="option.isTimeShow==1 && option.p_show==1 && option.type !== 33 && option.type !== 34"> -->
            <div class="w-100"
              v-if="option.p_show == 1 && option.type !== 33 && option.type !== 34 && option.content.S != 89 && option.content.S != 38 && option.content.S != 94 && option.content.S != 27">
              <div class="charRecord-time">{{ option.create_time }}</div>
            </div>
            <!-- 系统消息-->
            <div class="w-100"
              v-if="(option.content.S == 5 || option.content.S == 100 || option.content.S == 1 || option.content.S == 20 || option.content.S == 39) && option.p_show == 1">
              <div class="charRecord-leftItem">
                <div class="charRecord-image">
                  <img class="charHeadimg" src="../order/logo1.png">
                </div>
                <div class="hh"></div>
                <div class="charRecord-title p-10">{{ option.content.M }}</div>
              </div>
            </div>
            <!-- 左侧 完善目的地-->
            <div class="w-100" v-if="option.content.S == 27 && option.direction == 3">
              <div class="charRecord-leftItem">
                <div class="charRecord-image">
                  <img class="charHeadimg" src="../order/logo1.png">
                </div>
                <div class="hh"></div>
                <div class="charRecord-title ">
                  <div class="">
                    <div class="m-10 mb-0">您可以完善一下目的地</div>
                    <div class="whiteAddress">{{ orderInfo.place_address }}</div>
                    <div class="addressBtn">
                      <span class="ignore">忽略</span>
                      <span class="submit">提交</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 右侧 完善目的地-->
            <div class="w-100" v-if="option.content.S == 27 && option.direction == 1">
              <div class="charRecord-rightItem">
                <div class="charRecord-title2 ">
                  <div class="">
                    <div class="m-10 mb-0">您可以完善一下目的地</div>
                    <div class="whiteAddress">请输入您要去的地址</div>
                    <div class="addressBtn">
                      <span class="ignore" style="width: 100%;border-right: none">已忽略</span>
                    </div>
                  </div>
                </div>
                <div class="rhh"></div>
                <div class="charRecordrightImage">
                  <img class="charHeadimg" :src="option.user.headimg">
                </div>
              </div>
            </div>
            <!-- 左侧 语音-->
            <div class="w-100 " v-if="option.content.S == 21 && option.direction == 2">
              <div class="charRecord-leftItem">
                <div class="charRecord-image">
                  <img class="charHeadimg" :src="option.driver.headimg">
                </div>
                <div class="hh"></div>
                <div class="charRecord-title2 p-10 " :style="{ width: option.bgWidth }"
                  @click="handlePlay(option.content.P.URL, option.id, index)" v-if="option.isPlay == true">
                  <audio class="pasuVideo" :src="option.content.P.URL" :ref="'middle' + index">您的浏览器不支持</audio>
                  <div class="lvoide1">
                    <img src="../../../assets/images/ic_b_yuyin@2x.png">
                  </div>
                  <div>{{ option.content.P.LONG }}''</div>
                </div>
                <div class="charRecord-title2 p-10" :style="{ width: option.bgWidth }" v-if="option.isPlay == false">
                  <audio class="pasuVideo" :src="option.content.P.URL" :ref="'middle' + index">您的浏览器不支持</audio>
                  <div class="lvoide1">
                    <img src="../../../assets/images/ic_b_yuyin.gif">
                  </div>
                  <div>{{ option.content.P.LONG }}''</div>
                </div>
              </div>
            </div>
            <!-- 右侧 语音-->
            <div class="w-100 " v-if="option.content.S == 21 && option.direction == 1">
              <div class="charRecord-rightItem">
                <div class="charRecord-title2 p-10 bgGreen " :style="{ width: option.bgWidth }"
                  @click="handlePlay(option.content.P.URL, option.id, index)" v-if="option.isPlay == true">
                  <audio class="pasuVideo" :src="option.content.P.URL" :ref="'middle' + index">您的浏览器不支持</audio>
                  <div style="color:#fff">{{ option.content.P.LONG }}''</div>
                  <div class="rvoide1">
                    <img src="../../../assets/images/yuyin-ico.png" :ref="'changeImg' + index">
                  </div>

                </div>
                <div class="charRecord-title2 p-10 bgGreen" :style="{ width: option.bgWidth }"
                  v-if="option.isPlay == false">
                  <audio class="pasuVideo" :src="option.content.P.URL" :ref="'middle' + index">您的浏览器不支持</audio>

                  <div style="color:#fff">{{ option.content.P.LONG }}''</div>
                  <div class="rvoide1">
                    <img src="../../../assets/images/yuyin-gif.gif">
                  </div>

                </div>
                <div class="rhh1"></div>
                <div class="charRecordrightImage">
                  <img class="charHeadimg" :src="option.user.headimg">
                </div>
              </div>
            </div>
            <!-- 右侧 图片-->
            <div class="w-100" v-if="option.content.S == 22">
              <div class="charRecord-rightItem">
                <div class="charRecord-title1 p-10" @click="hanlePicture(option.content.P.URL)">
                  <img class="" style="max-width: 370px;" :src="option.content.P.URL">
                </div>
                <div class="rhh"></div>
                <div class="charRecord-image">
                  <img width='100px' height='100px' class="charHeadimg" :src="option.user.headimg">
                </div>
              </div>
            </div>
            <!-- 右侧 视频-->
            <div class="w-100" v-if="option.content.S == 6">
              <div class="charRecord-rightItem">
                <div class="charRecord-title1 p-10">
                  <video width="100" height="100" controls>
                    <source :src="option.content.P.URL" type="video/mp4">
                  </video>
                </div>
                <div class="rhh"></div>
                <div class="charRecord-image">

                  <img class="charHeadimg" :src="option.user.headimg">
                </div>
              </div>
            </div>
            <!-- 右侧 地图-->
            <div class="w-100" v-if="option.content.S == 26 && option.direction == 1">
              <div class="charRecord-rightItem">
                <div class="charRecord-titleMap p-10">
                  <div class="amap-page-container">
                    <div :style="{ width: '100%', height: '180px' }">
                      <!-- vid="amapDemo1" -->
                      <el-amap ref="map" :zoom="zoom" :center="option.content.P.POINT">
                        <el-amap-marker></el-amap-marker>
                      </el-amap>
                    </div>
                  </div>
                </div>
                <div class="rhh"></div>
                <div class="charRecord-image">
                  <img class="charHeadimg" :src="option.user.headimg">
                </div>
              </div>
            </div>
            <!-- 左侧 地图-->
            <div class="w-100" v-if="option.content.S == 26 && option.direction == 2">
              <div class="charRecord-leftItem">
                <div class="charRecord-image">
                  <img class="charHeadimg" :src="option.driver.headimg">
                </div>
                <div class="hh"></div>
                <div class="charRecord-titleMap p-10">
                  <div class="amap-page-container">
                    <div :style="{ width: '100%', height: '180px' }">
                      <el-amap ref="map" :zoom="zoom" :center="option.content.P.POINT">
                        <el-amap-marker></el-amap-marker>
                      </el-amap>
                    </div>
                  </div>
                </div>


              </div>
            </div>

            <!-- 左侧 语音通话 对方已取消-->
            <div class="w-100"
              v-if="option.content.S == 60 && option.direction == 2 && option.content.P.call_status == 3 && option.p_show == 1">
              <div class="charRecord-leftItem">
                <div class="charRecordrightImage">
                  <img class="charHeadimg" :src="option.driver.headimg">
                </div>
                <div class="hh"></div>

                <div class="charRecord-title2 p-10 disCenter voide">
                  <div class=" mr-10">
                    <img src="../../../assets/images/voice/ic_phone3grey@2x.png">
                  </div>
                  <div>{{ option.content.P.voiceCallMessage }}</div>
                </div>
              </div>
            </div>
            <!-- 左侧 语音通话 已拒接-->
            <div class="w-100"
              v-if="option.content.S == 60 && option.direction == 2 && option.content.P.call_status == 4 && option.p_show == 1">
              <div class="charRecord-leftItem">
                <div class="charRecordrightImage">
                  <img class="charHeadimg" :src="option.driver.headimg">
                </div>
                <div class="hh"></div>

                <div class="charRecord-title2 p-10 disCenter voide">
                  <div class=" mr-10">
                    <img src="../../../assets/images/voice/ic_phone3grey@2x.png">
                  </div>
                  <div>{{ option.content.P.message }}</div>
                </div>
              </div>
            </div>
            <!-- 左侧 语音通话 未接听-->
            <div class="w-100"
              v-if="option.content.S == 60 && option.direction == 2 && option.content.P.call_status == 5 && option.p_show == 1">
              <div class="charRecord-leftItem">
                <div class="charRecordrightImage">
                  <img class="charHeadimg" :src="option.driver.headimg">
                </div>
                <div class="hh"></div>

                <div class="charRecord-title2 p-10 disCenter voide">
                  <div class=" mr-10">
                    <img src="../../../assets/images/voice/call_ic_blackwait.png">
                  </div>
                  <div>{{ option.content.P.voiceCallMessage }}</div>
                </div>
              </div>
            </div>
            <!-- 左侧 语音通话 通话时长-->
            <div class="w-100"
              v-if="option.content.S == 60 && option.direction == 2 && option.content.P.call_status == 6">
              <div class="charRecord-leftItem">
                <div class="charRecordrightImage">
                  <img class="charHeadimg" :src="option.driver.headimg">
                </div>
                <div class="hh"></div>

                <div class="charRecord-title2 p-10 disCenter voide">
                  <div class=" mr-10">
                    <img src="../../../assets/images/voice/ic_phonegrey@2x.png">
                  </div>
                  <div>{{ option.content.P.message }}</div>
                </div>
              </div>
            </div>
            <!-- 右侧 语音通话 已取消-->
            <div class="w-100"
              v-if="option.content.S == 60 && option.direction == 1 && option.content.P.call_status == 3 && option.p_show == 1">
              <div class="charRecord-rightItem">
                <div class="charRecord-title2 p-10 disCenter voide bgGreen">
                  <div class=" mr-10">
                    <img src="../../../assets/images/voice/ic_phone3@2x.png">
                  </div>
                  <div>{{ option.content.P.message }}</div>
                </div>
                <div class="rhh1"></div>
                <div class="charRecordrightImage">
                  <img class="charHeadimg" :src="option.user.headimg">
                </div>
              </div>
            </div>
            <!-- 右侧 语音通话 对方已拒接-->
            <div class="w-100"
              v-if="option.content.S == 60 && option.direction == 1 && option.content.P.call_status == 4 && option.p_show == 1">
              <div class="charRecord-rightItem">
                <div class="charRecord-title2 p-10 disCenter voide bgGreen">
                  <div class=" mr-10">
                    <img src="../../../assets/images/voice/ic_phone3@2x.png">
                  </div>
                  <div>{{ option.content.P.voiceCallMessage }}</div>
                </div>
                <div class="rhh1"></div>
                <div class="charRecordrightImage">
                  <img class="charHeadimg" :src="option.user.headimg">
                </div>
              </div>
            </div>
            <!-- 右侧 语音通话 对方无应答-->
            <div class="w-100"
              v-if="option.content.S == 60 && option.direction == 1 && option.content.P.call_status == 5 && option.p_show == 1">
              <div class="charRecord-rightItem">
                <div class="charRecord-title2 p-10 disCenter voide bgGreen">
                  <div class=" mr-10">
                    <img src="../../../assets/images/voice/call_ic_whitewait.png">
                  </div>
                  <div>{{ option.content.P.message }}</div>
                </div>
                <div class="rhh1"></div>
                <div class="charRecordrightImage">
                  <img class="charHeadimg" :src="option.user.headimg">
                </div>
              </div>
            </div>
            <!-- 右侧 语音通话 通话时长-->
            <div class="w-100"
              v-if="option.content.S == 60 && option.direction == 1 && option.content.P.call_status == 6">

              <div class="charRecord-rightItem">
                <div class="charRecord-title2 p-10 disCenter voide bgGreen">
                  <div class=" mr-10">
                    <img src="../../../assets/images/voice/ic_phone@2x.png">
                  </div>
                  <div>{{ option.content.P.message }}</div>
                </div>
                <div class="rhh1"></div>
                <div class="charRecordrightImage">
                  <img class="charHeadimg" :src="option.user.headimg">
                </div>
              </div>
            </div>
            <!-- 左侧 订单账单-->
            <div class="w-100" v-if="option.content.S == 31">
              <div class="charRecord-leftItem">
                <div class="charRecord-image">
                  <img class="charHeadimg" src="../order/logo1.png" />
                </div>
                <div class="hh"></div>
                <div class="charRecord-Paytitle">
                  <div class="w-30">
                    <div class="m-10 mb-0 text-center">订单账单</div>
                    <div class="m-10 mb-0 text-center">
                      <span class="price">{{ option.show_data.price }}</span>元
                    </div>
                    <div class="p-10 text-center payBox">
                      <div class="payTitle">行程车费</div>
                      <div class="payPrice">{{ option.show_data.price }}元</div>
                    </div>
                    <div class="p-10  payBox">
                      <div class="payTitle">零钱抵现</div>
                      <div class="payPrice"><span style="color: #FF9D00;">-{{ option.show_data.bonus }}</span>元</div>
                    </div>
                    <div class="p-10 payType">
                      <div>
                        <img src="../../../assets/images/share_ic_wechat@2x.png" style="float: left;" alt="" width="30px"
                          height="30px;">
                        <span class="wxPay">微信支付</span>
                      </div>

                      <img src="../../../assets/images/ic_arrow@2x.png" style="float: right;margin-top: 3px;" alt=""
                        width="10px" height="20px;">

                    </div>

                    <div class="p-10 payType borderTop">
                      <div>
                        <img src="../../../assets/images/ic_pay@2x.png" style="float: left;" alt="" width="30px"
                          height="30px;">
                        <span class="wxPay">线下支付</span>
                      </div>

                      <img src="../../../assets/images/ic_arrow@2x.png" style="float: right;margin-top: 3px;" alt=""
                        width="10px" height="20px;">
                    </div>


                  </div>
                </div>

              </div>
            </div>
            <!-- 左侧 违约金账单-->
            <div class="w-100" v-if="option.content.S == 46">
              <div class="charRecord-leftItem">
                <div class="charRecord-image">
                  <img class="charHeadimg" src="../order/logo1.png" />
                </div>
                <div class="hh"></div>
                <div class="charRecord-Paytitle">
                  <div class="w-30">
                    <div class="m-10 mb-0 text-center">违约金账单</div>
                    <div class="m-10 mb-0 text-center">
                      <span class="price">{{ option.content.P.PRICE }}</span>元
                    </div>
                    <div class="p-10 payType">
                      <div>
                        <img src="../../../assets/images/share_ic_wechat@2x.png" style="float: left;" alt="" width="30px"
                          height="30px;">
                        <span class="wxPay">微信支付</span>
                      </div>

                      <img src="../../../assets/images/ic_arrow@2x.png" style="float: right;margin-top: 3px;" alt=""
                        width="10px" height="20px;">

                    </div>

                  </div>
                </div>

              </div>

            </div>
            <!-- 右侧 违约金支付成功-->
            <div class="w-100" v-if="option.content.S == 36 && option.p_show == 1">
              <div class="charRecord-rightItem">
                <div class="charRecord-Paytitle ">
                  <div>
                    <div class="paySuccess">支付成功</div>
                    <div class="money">
                      <span style="color:#84B625;font-size:25px;text-align:center">{{ option.content.PRICE }}</span>元
                    </div>
                  </div>

                </div>
                <div class="rhh"></div>
                <div class="charRecordRightImage">
                  <img class="charHeadimg" :src="option.user.headimg" />
                </div>

              </div>
            </div>
            <!-- 右侧 支付成功-->
            <div class="w-100" v-if="option.content.S == 32 && option.p_show == 1">
              <div class="charRecord-rightItem">
                <div class="charRecord-Paytitle ">
                  <div>
                    <div class="paySuccess">支付成功</div>
                    <div class="money">
                      <span style="color:#84B625;font-size:25px;text-align:center">{{ option.content.PRICE }}</span>元
                    </div>
                    <div class="otherPay">红包另支付0元</div>
                  </div>

                </div>
                <div class="rhh"></div>
                <div class="charRecordRightImage">
                  <img class="charHeadimg" :src="option.user.headimg" />
                </div>

              </div>
            </div>

            <!-- 左侧 被动红包 -->
            <div class="w-100" v-if="option.content.S == 72">
              <div class="charRecord-leftItem">
                <div class="charRecord-image">
                  <img class="charHeadimg" :src="option.driver.headimg">
                </div>
                <div class="hh"></div>
                <div class="charRecord-Paytitle">
                  <div class="">
                    <div class="text-align pbt-10">对话红包</div>
                    <div class="bill-box">
                      <div class="">红包金额</div>
                      <div class=""><span>{{ option.content.P.PRICE }}</span>元</div>
                    </div>
                    <div class="btn">
                      <div class="btn-ignore">驳回</div>
                      <div class="line"></div>
                      <div class="btn-ignore">发红包</div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <!-- 右侧 被动红包-->
            <div class="w-100" v-if="option.content.S == 69">
              <div class="charRecord-rightItem">
                <div class="charRecord-Paytitle">
                  <div class="">
                    <div class="text-align pbt-10">对话红包</div>
                    <div class="bill-box">
                      <div class="">红包金额</div>
                      <div class=""><span>0.01</span>元</div>
                    </div>
                    <div class="btn">
                      <div class="btn-ignore text-align">已驳回</div>
                    </div>
                  </div>
                </div>
                <div class="rhh"></div>
                <div class="charRecord-image">
                  <img class="charHeadimg" :src="option.user.headimg">
                </div>
              </div>
            </div>
            <!-- 右侧 未领取主动红包-->
            <div class="w-100" v-if="option.content.S == 71 && option.content.H == 1">
              <div class="charRecord-rightItem">
                <div class="charRecord-title3">
                  <img src="../../../assets/images/bg_hb1@2x.png" style="position:relative;width:100%;height:100%" />

                  <div class="red-box" style="position:absolute;top:30px">
                    <div>
                      <img class="package" src="../../../assets/images/img_hb1@2x.png" />
                    </div>
                    <div class="">
                      <div class="">主动红包，司机可领取</div>
                      <div class="font--9">未领取</div>
                    </div>
                  </div>
                  <div class="address-box" style="position:absolute;top:64px">
                    <div class="">
                      <img class="weizhi" src="../../../assets/images/hb_logo@2x.png" />
                    </div>
                    <div class="font--9">咱县打车</div>
                  </div>
                </div>

                <div class="charRecord-image">
                  <img class="charHeadimg" :src="option.user.headimg">
                </div>
              </div>
            </div>
            <!-- 右侧 已领取主动红包-->
            <div class="w-100" v-if="option.content.S == 71 && option.content.H == 2">
              <div class="charRecord-rightItem">
                <div class="charRecord-title3">
                  <img src="../../../assets/images/bg_hb2@2x.png" style="position:relative;width:100%;height:100%" />

                  <div class="red-box" style="position:absolute;top:30px">
                    <div>
                      <img class="package" src="../../../assets/images/img_hb2@2x.png" />
                    </div>
                    <div class="">
                      <div class="">主动红包，司机可领取</div>
                      <div class="font--9">未领取</div>
                    </div>
                  </div>
                  <div class="address-box" style="position:absolute;top:64px">
                    <div class="">
                      <img class="weizhi" src="../../../assets/images/hb_logo@2x.png" />
                    </div>
                    <div class="font--9">咱县打车</div>
                  </div>
                </div>

                <div class="charRecord-image">
                  <img class="charHeadimg" :src="option.user.headimg">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div class="ampBox" :style="{ display: ampDisplay == true ? 'block' : 'none' }" @click="handleClose">
        <img :src="ampSrc">
      </div>
    </el-dialog>
  </div>
</template>
<style>
.ele-form-mysearch {
  float: right
}
</style>
<script src="https://webapi.amap.com/maps?v=1.4.13&key=645cd97a39f5c47bafce4a316785f342"></script>
<script src="https://a.amap.com/jsapi_demos/static/demo-center/js/demoutils.js"></script>
<script>
  import download from '@/components/Download/index'
  import { mapGetters } from "vuex";

  export default {
    name: "SysUser",
    components: {download},
    data() {
      return {
        pickerOptionsStart: {
          disabledDate: time => {
            let endDateVal = this.tableend_time;
            if (endDateVal) {
              return time.getTime() > new Date(endDateVal).getTime();
            }
          }
        },
        pickerOptionsEnd: {
          disabledDate: time => {
            let beginDateVal = this.tableCreate_time;
            if (beginDateVal) {
              return (
                      time.getTime() <
                      new Date(beginDateVal).getTime()
              );
            }
          }
        },
        bgWidth: '',
        tableCreate_time: '', //搜索选中时间戳
        tableend_time:'',
        aa: true,
        ampDisplay: false,
        dialogVisibleAdd: false,
        gStar: [0, 0, 0, 0, 0],
        gStartext: [false, false, false, false, false],
        charList: [],
        table: {url: '/finance/with_ecord_list', where: {}},  // 表格配置
        choose: [],  // 表格选中数据
        editForm: {},  // 表单数据
        checkForm:{},
        editRules: {  // 表单验证规则

        },
        dialogTableVisibleCheck:false,
        dialogTableVisibleChatRecord:false,
        provArr:[],
        cityArr:[],
        districtArr:[],
        audioArr:[],
        urgentList:[],
        imageUrl:false,
        headImg:'',
        zoom:12,
        center:[104.07,30.67],
        dialogTableVisibleRecords: false,
        pageSize: 10,
        pageNumber: 1,
        recordsTotal: 1000,
        recordsShow: false,
        recordsId: '',
        loading: true,
        withdraw_price: 0,
        driverInfo: [],
        orderInfo: [],
        recordsList: [],
        row_data: [],
        charList: [],
        ampDisplay: false,
        ampSrc: '',
        audio: '',
        time:[],
      }
    },
    created(){
      this.$http.get('/common/province_list').then(res=>{
        let data=JSON.parse(res.data)
        this.provArr=data
      })
    },
    computed: {
      ...mapGetters(["permission"]),
    },

    mounted() {
    },
    methods: {
      birthdayChange(){

        this.time[0]=this.tableCreate_time
        this.table.where.create_time = this.time;

        //console.log(this.tableCreate_time)
        //console.log(this.table.where.create_time)
      },
      birthdayChange1(){
     
        this.time[1]=this.tableend_time
        this.table.where.create_time = this.time;
        //console.log(this.table.where.create_time)


      },
      exportOrderParams() {
        const query = this.table.where
        // const params = this.params
        const selectionIds = this.choose.map(item => {
          return item.id
        })

        return {ids: selectionIds, ...query}
      },
      /**
       *选择省
       **/
      handleChangeProv(e){
        //console.log(e)
        /** 获取被选省份的pid**/
        let pid=''
        this.provArr.forEach(function(item){
          if(item.pid==e){
            pid=item.pid
          }
        })
        /** 根据被选省份的pid获取省市下面的市**/
        this.$http.post('/common/city_list',{pid:pid}).then(res=>{
          let data=JSON.parse(res.data)
          this.cityArr=data
          /** 选择省份清空市县**/
          this.table.where.cid=''
          this.table.where.aid=''
        })
      },
      /**
       *选择市
       **/
      handleChangeCity(e){
        if(e){
          /** 获取被选市的cid**/
          let cid=''
          this.cityArr.forEach(function(item){
            if(item.cid==e){
              cid=item.cid
            }
          })
          /** 根据被选市的cid获取市下面的县**/
          this.$http.post('/common/area_list',{cid:cid}).then(res=>{
            let data=JSON.parse(res.data)
            this.districtArr=data
            /** 选择省份清空县**/
            this.table.where.aid=''
          })
        }else{
          this.table.where.pid = ''
          this.table.where.aid = ''
        }
      },
      //申请驳回操作
      with_error(row){
        this.$confirm('确定要驳回该申请吗?', '提示', {type: 'warning'}).then(() => {
          const loading = this.$loading({lock: true});
          this.$http.post('/finance/with_error', {id: row.id}).then(res => {
            loading.close();
            if (res.data.code === 0) {
              this.$message({type: 'success', message: res.data.msg});
              this.$refs.table.reload();
            } else {
              this.$message.error(res.data.msg);
            }
          }).catch(e => {
            loading.close();
            this.$message.error(e.message);
          });
        })
      },
      // 提现申请审核通过
      with_success(row){
        this.$confirm('确定要通过该申请吗?', '提示', {type: 'warning'}).then(() => {
          const loading = this.$loading({lock: true});
          this.$http.post('/finance/with_success', {id: row.id}).then(res => {
            loading.close();
            if (res.data.code === 0) {
              this.$message({type: 'success', message: res.data.msg});
              this.$refs.table.reload();
            } else {
              this.$message.error(res.data.msg.err_code_des);
            }
          }).catch(e => {
            loading.close();
            this.$message.error(e.message);
          });
        })
      },

      /**
       *分页
       **/
      handleCurrentChange(e) {
        this.pageNumber=e
        this.getRecords(this.row_data)
      },
      view_records(row) {
        this.dialogTableVisibleRecords = true
        this.withdraw_price = row.money
        this.row_data = row
        this.pageNumber = 1
        this.getRecords(row)
      },
      /**
       *查看提现订单
       **/
      getRecords(row) {
        this.recordsList = [];
        var formData = {
          uid: row.uid,
          earning_id: row.earning_id,
          type: 1,
          pageSize: this.pageSize,
          pageNumber: this.pageNumber,
        }
        this.$http.post('/finance/view_records', formData).then(res => {
          console.log('提现订单明细的res',res.data.count);
          var data = res.data;
          this.recordsTotal = data.count
          if (data.count == 0 || data.count == 'null') {
            this.recordsShow = false
          } else {
            this.recordsShow = true
          }
          if (data.data.length) {
            this.recordsList = data.data

          }
        })
      },

      /**
       * 聊天记录
       * @param id
       */
      getChat(id) {
        this.dialogTableVisibleChatRecord = true
        this.charList = []
                this.driverInfo = {}
                this.orderInfo = {}
        const loading = this.$loading({
          lock: true,
          target: document.querySelector(".records")
        });
        this.$http.post('/order/chat_log', {
          id: id
        }).then(res => {
          var data = res.data
          loading.close()
          data.log.forEach(function(item) {
            item.isTimeShow = 1
            if (item.content.S == 21) {
              item.isPlay = true
              if (item.content.P.LONG == 1) {
                item.bgWidth = '8rem'
              }
              item.bgWidth = 8 + 0.05 * (parseInt(item.content.P.LONG) - 1) + 'rem'
            }
            if (item.content.S == 26) {
              item.content.P.POINT = item.content.P.POINT.split(',')
              //console.log(item.content.P.POINT)

            }
          });
          for (var i = 0; i < data.log.length; i++) {
            for (var j = i + 1; j < data.log.length - 1; j++) {
              if (data.log[i].create_time == data.log[j].create_time) {
                data.log[j].isTimeShow = 0 //不显示时间
              } else {
                data.log[j].isTimeShow = 1
              }
            }
          }
          this.charList = data.log
          this.driverInfo = data.driver
          this.orderInfo = data.order_info
        })

      },
      hanlePicture(src) {
        this.ampDisplay = true
        this.ampSrc = src
      },
      handleClose() {
        this.ampDisplay = false
      },
      closeDialog() {
        var middlePlay = document.querySelectorAll('.pasuVideo')
        for (let i = 0; i < middlePlay.length; i++) {
          middlePlay[i].pause()
          middlePlay[i].currentTime = 0
        }
      },
      handlePlay(url, id, index) {
        let that = this
        var middlePlay = document.querySelectorAll('.pasuVideo')
        for (let i = 0; i < middlePlay.length; i++) {
          middlePlay[i].pause()
          middlePlay[i].currentTime = 0
        }
        var middle = 'middle' + index
        middlePlay = this.$refs[middle][0]
        middlePlay.play()
        for (let i = 0; i < this.charList.length; i++) {
          if (i == index) {
            this.charList[index].isPlay = false
            middlePlay.addEventListener('ended', function() {
              that.charList[index].isPlay = true
            })
          } else {
            that.charList[i].isPlay = true
          }
        }
      },

    }
  }
</script>

